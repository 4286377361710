"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
$(document).ready(function ($) {
  var page;
  // let page = 1;
  var resetFiltersButton = $('#reset-filters');
  var applyFiltersButton = $('#apply-filters');
  var paginationContainer = $('#pagination-blog');
  function applyFilters(page) {
    var selectedCategories = [];
    $('input[name="category[]"]:checked').each(function () {
      selectedCategories.push($(this).val());
    });
    var sortOption = $('input[name="sort"]:checked').val();
    var searchQuery = $('#search').val(); // Получаем значение поля поиска

    var data = {
      category: selectedCategories,
      sort: sortOption,
      search: searchQuery,
      // Добавляем поисковый запрос
      paged: page
    };
    $.ajax({
      url: ajaxUrl + '?action=filter_blog',
      type: 'GET',
      data: data,
      dataType: 'json',
      success: function success(response) {
        $('#articles-container').empty();
        paginationContainer.empty();
        $('#articles-container').append(response.posts);
        paginationContainer.append(response.pagination);
      },
      error: function error(xhr, status, _error) {
        console.log('Error: ' + _error);
      }
    });

    // // Управляем отображением кнопки сброса фильтров
    // resetFiltersButton.toggle(selectedCategories.length > 1);
    //
    if ($(window).width() <= 768) {
      applyFiltersButton.toggle(selectedCategories.length > 0);
    }
  }
  paginationContainer.on('click', function (e) {
    if (e.target.classList.contains('page-numbers')) {
      e.preventDefault();
      var _page = 1; // По умолчанию считаем, что это первая страница
      var href = e.target.getAttribute('href');
      if (href.includes('/page/')) {
        _page = href.split('/page/').pop(); // Получаем номер страницы из URL
      }

      applyFilters(_page);
      var postsContainer = document.getElementById('articles-container');
      var scrollToOptions = {
        top: postsContainer.getBoundingClientRect().top + window.pageYOffset - 200,
        behavior: 'smooth'
      };
      window.scrollTo(scrollToOptions);
    }
  });

  // Управление фильтрами при изменении значений
  function handleFilters() {
    // Обновляем фильтры
    page = 1; // Сбрасываем номер страницы при любом изменении фильтра
    applyFilters(page); // Применяем фильтры
  }

  // Применение фильтров при изменении чекбоксов категорий или полей поиска
  $('input[name="category[]"]').on('change keyup', function () {
    var selectedCategories = $('input[name="category[]"]:checked').length;
    if (selectedCategories > 0) {
      // Снимаем все выбранные опции в other-options
      $('input[name="sort"]').prop('checked', false);
    }
    handleFilters(); // Обрабатываем фильтры
  });

  $('input[name="sort"]').on('change keyup', function () {
    var selectedSortOption = $('input[name="sort"]:checked').length;
    if (selectedSortOption > 0) {
      // Снимаем все выбранные опции в category-filter
      $('input[name="category[]"]').prop('checked', false);
    }
    handleFilters(); // Обрабатываем фильтры

    if (window.innerWidth < 768) {
      setTimeout(function () {
        $('.filter-left').removeClass('open');
      }, 300);
    }
  });
  $('#search').on('change keyup', function () {
    var searchValue = $(this).val().trim();

    // Если поле поиска пустое, вызываем handleFilters
    if (searchValue === '') {
      handleFilters();
    }
  });

  // Сбрасываем фильтры при нажатии на кнопку "Сбросить фильтры"
  resetFiltersButton.on('click', function (e) {
    e.preventDefault(); // Предотвращаем стандартное действие кнопки
    $('#filter-form')[0].reset(); // Сбрасываем форму
    page = 1; // Сбрасываем номер страницы
    handleFilters(page); // Обрабатываем фильтры после сброса
  });

  applyFiltersButton.on('click', function (e) {
    e.preventDefault();
    page = 1;
    handleFilters(page);
    $('.filter-left').removeClass('open');
  });
  $('#search-btn').on('click', function () {
    var $input = $('.wrapper-search input[type="text"]');
    if ($input.hasClass('active')) {
      handleFilters(); // Обрабатываем фильтры при нажатии кнопки поиска
    }
  });
});

$(document).ready(function () {
  var $categoryFilterLabel = $('label[for="category-filter"]');
  var $categoryFilterList = $('#category-filter');
  var $filterForm = $('#filter-form');
  var $closeBtn = $('.close-btn');
  $categoryFilterLabel.on('click', function () {
    if (!$categoryFilterList.hasClass('show')) {
      $categoryFilterLabel.addClass('active');
      if ($(window).width() <= 768) {
        $categoryFilterList.css('display', 'flex');
      } else {
        $categoryFilterList.css('display', 'flex');
      }
      setTimeout(function () {
        $categoryFilterList.addClass('show'); // Добавляем класс для появления
      }, 10); // Небольшая задержка для работы анимации
      // $filterForm.addClass('open');
    } else {
      $categoryFilterList.removeClass('show'); // Убираем класс для исчезновения
      setTimeout(function () {
        $categoryFilterList.css('display', 'none'); // Скрываем блок после завершения анимации
      }, 300); // Задержка равна времени transition в CSS
      $categoryFilterLabel.removeClass('active');
      // $filterForm.removeClass('open');
    }
  });

  $('.wrapper-search #search-btn').on('click', function () {
    var $input = $('.wrapper-search input[type="text"]');
    if ($input.hasClass('active')) {
      if ($input.val().trim() === "") {
        $input.removeClass('active');
      }
    } else {
      $input.addClass('active').focus(); // Автоматически фокусируемся на поле ввода
    }
  });

  $('.wrapper-search input[type="text"]').on('blur', function () {
    if ($(this).val().trim() === "") {
      $(this).removeClass('active');
    }
  });
  if ($(window).width() <= 768) {
    $('.filter-top').on('click', function () {
      $('.filter-left').toggleClass('open');
    });
  }
  $closeBtn.on('click', function () {
    $('.filter-left').removeClass('open');
  });
  document.querySelector('#apply-filters').addEventListener('click', function (event) {
    $('.filter-left').removeClass('open');
  });
});
$(document).ready(function () {
  var categoryFilter = document.getElementById('category-filter');
  var tagFilter = document.getElementById('tag-filter');
  var paginationContainer = document.getElementById('pagination-links');
  function applyFilters(page) {
    var formData = new FormData(document.getElementById('filter-form'));
    formData.append('paged', page); // Добавляем параметр страницы в запрос
    var xhr = new XMLHttpRequest();
    xhr.open('GET', ajaxUrl + '?action=filter_posts&' + new URLSearchParams(formData).toString(), true);
    xhr.onload = function () {
      if (xhr.status === 200) {
        var response = JSON.parse(xhr.responseText);
        document.getElementById('posts-container').innerHTML = response.posts; // Обновляем контейнер с записями
        paginationContainer.innerHTML = response.pagination; // Обновляем пагинацию
      }
    };

    xhr.send();
  }
  categoryFilter.addEventListener('change', function () {
    applyFilters(1); // При изменении фильтрации обновляем на первую страницу
  });

  tagFilter.addEventListener('change', function () {
    applyFilters(1); // При изменении фильтрации обновляем на первую страницу
  });

  paginationContainer.addEventListener('click', function (e) {
    if (e.target.classList.contains('page-numbers')) {
      e.preventDefault();
      var page = 1; // По умолчанию считаем, что это первая страница
      var href = e.target.getAttribute('href');
      if (href.includes('/page/')) {
        page = href.split('/page/').pop(); // Получаем номер страницы из URL
      }

      applyFilters(page);
      var postsContainer = document.getElementById('posts-container');
      var scrollToOptions = {
        top: postsContainer.getBoundingClientRect().top + window.pageYOffset - 200,
        behavior: 'smooth'
      };
      window.scrollTo(scrollToOptions);
    }
  });
  var notClickableCards = document.querySelectorAll('.not-clickable');
  notClickableCards.forEach(function (card) {
    card.addEventListener('click', function (event) {
      event.preventDefault();
    });
  });
  var customCategorySelect = document.querySelector('.select__list_category');
  var customTagSelect = document.querySelector('.select__list_tag');

  // Получаем реальные селекты
  var categorySelect = document.getElementById('category-filter');
  var tagSelect = document.getElementById('tag-filter');

  // Функция для обновления реального селекта по выбору в кастомном селекте
  function updateRealSelect(customSelect, realSelect) {
    // Получаем выбранный элемент в кастомном селекте
    var selectedItem = customSelect.querySelector('.selected');

    // Получаем значение атрибута data-category или data-tag выбранного элемента
    var value = selectedItem.getAttribute('data-' + realSelect.getAttribute('name'));

    // Устанавливаем выбранное значение в реальный селект
    realSelect.value = value;
  }

  // Функция для обновления кастомного селекта по выбору в реальном селекте
  function updateCustomSelect(customSelect, realSelect) {
    // Получаем выбранное значение в реальном селекте
    var selectedValue = realSelect.value;

    // Сбрасываем предыдущий выбор в кастомном селекте
    var selectedItems = customSelect.querySelectorAll('.selected');
    selectedItems.forEach(function (item) {
      item.classList.remove('selected');
    });

    // Находим элемент в кастомном селекте с соответствующим значением и выделяем его
    var selectedItem = customSelect.querySelector('[data-' + realSelect.getAttribute('name') + '="' + selectedValue + '"]');
    if (selectedItem) {
      selectedItem.classList.add('selected');
    }
  }

  // Обработчик клика по элементу в кастомном селекте категорий
  // Обработчик клика по элементу в кастомном селекте категорий
  customCategorySelect.addEventListener('click', function (event) {
    var selectedItem = event.target;
    if (selectedItem.classList.contains('select__item')) {
      // Устанавливаем класс selected выбранному элементу и снимаем его с остальных
      var items = customCategorySelect.querySelectorAll('.select__item');
      items.forEach(function (item) {
        item.classList.remove('selected');
      });
      selectedItem.classList.add('selected');

      // Обновляем реальный селект по выбору в кастомном селекте
      updateRealSelect(customCategorySelect, categorySelect);

      // Применяем фильтры
      applyFilters(1);
    }
  });

  // Обработчик клика по элементу в кастомном селекте тегов
  customTagSelect.addEventListener('click', function (event) {
    var selectedItem = event.target;
    if (selectedItem.classList.contains('select__item')) {
      // Устанавливаем класс selected выбранному элементу и снимаем его с остальных
      var items = customTagSelect.querySelectorAll('.select__item');
      items.forEach(function (item) {
        item.classList.remove('selected');
      });
      selectedItem.classList.add('selected');

      // Обновляем реальный селект по выбору в кастомном селекте
      updateRealSelect(customTagSelect, tagSelect);

      // Применяем фильтры
      applyFilters(1);
    }
  });

  // Обработчик изменения значения реального селекта категорий
  categorySelect.addEventListener('change', function () {
    // Обновляем кастомный селект по выбору в реальном селекте
    updateCustomSelect(customCategorySelect, categorySelect);
  });

  // Обработчик изменения значения реального селекта тегов
  tagSelect.addEventListener('change', function () {
    // Обновляем кастомный селект по выбору в реальном селекте
    updateCustomSelect(customTagSelect, tagSelect);
  });
  $('.select').on('click', '.select__head', function () {
    if ($(this).hasClass('open')) {
      $(this).removeClass('open');
      $(this).next().fadeOut(100);
    } else {
      $('.select__head').removeClass('open');
      $('.select__list').fadeOut();
      $(this).addClass('open');
      $(this).next().fadeIn(100);
    }
  });
  $('.select').on('click', '.select__item', function () {
    $('.select__head').removeClass('open');
    $(this).parent().fadeOut(100);
    $(this).parent().prev().text($(this).text());
    $(this).parent().prev().prev().val($(this).text());
  });
  $(document).click(function (e) {
    if (!$(e.target).closest('.select').length) {
      $('.select__head').removeClass('open');
      $('.select__list').fadeOut(100);
    }
  });
});
var navToggler = document.querySelector('.nav-toggler');
var navMenu = document.querySelector('.nav ul');
var navLinks = document.querySelectorAll('.nav a');
allEventListners();
function allEventListners() {
  // toggler icon click event
  navToggler.addEventListener('click', togglerClick);
  // nav links click event
  navLinks.forEach(function (elem) {
    return elem.addEventListener('click', navLinkClick);
  });
}
function togglerClick() {
  navToggler.classList.toggle('toggler-open');
  navMenu.classList.toggle('open');
}
function navLinkClick() {
  if (navMenu.classList.contains('open')) {
    navToggler.click();
  }
}
$(document).ready(function () {
  $(".nav-toggler").click(function () {
    $(this).toggleClass("active");
    $("#nav").toggle();
    // $(".header-top .container").toggleClass("hide")
    $(".header-bottom").toggleClass("active");
    $(".sub-menu-main").toggle();
  });
  $("#sub-menu-nav .menu-item-has-children").click(function () {
    if ($(this).hasClass("active")) {
      return;
    }
    var button = $("<button>").text("назад");
    var svg = '<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">' + '<path fill-rule="evenodd" clip-rule="evenodd" d="M5.93262 11.0199C5.68058 11.2719 5.27194 11.2719 5.01991 11.0199L0.45635 6.45635C0.204312 6.20431 0.204312 5.79568 0.45635 5.54364L5.01991 0.980083C5.27194 0.728045 5.68058 0.728045 5.93262 0.980084C6.18466 1.23212 6.18466 1.64076 5.93262 1.89279L2.4708 5.35461L10.9525 5.35461C11.309 5.35461 11.5979 5.64356 11.5979 5.99999C11.5979 6.35643 11.309 6.64538 10.9525 6.64538L2.4708 6.64538L5.93262 10.1072C6.18466 10.3592 6.18466 10.7679 5.93262 11.0199Z" fill="#FCFFF5"/>' + '</svg>';
    button.prepend(svg);
    button.addClass("back");
    $("#sub-menu-nav").append(button);
    $("#sub-menu-nav li").addClass("hide");
    $(this).removeClass("hide");
    $("#sub-menu-nav .menu-item-has-children").addClass("active");
    $(".sub-menu").addClass("active");
    $(".sub-menu li a").each(function (index) {
      var listItem = $(this);
      setTimeout(function () {
        listItem.addClass("slideDown");
      }, index * 400);
    });
  });
  $("#sub-menu-nav").on("click", ".back", function () {
    $(this).remove();
    $("#sub-menu-nav li").removeClass("hide");
    $("#sub-menu-nav .menu-item-has-children").removeClass("active");
    $(".sub-menu").removeClass("active");
    $(".sub-menu li a").removeClass("slideDown");
  });
  function scrollToTop() {
    totopBtnToggle();
    $(".totop").click(function () {
      $("html, body").animate({
        scrollTop: 0
      }, "slow");
      return false;
    });
  }
  function totopBtnToggle() {
    var totopBtn = document.querySelector('.totop');
    if (totopBtn) {
      window.addEventListener('scroll', function () {
        if (window.scrollY > 1000) {
          totopBtn.classList.add('visible');
        } else {
          totopBtn.classList.remove('visible');
        }
      });
    }
  }
  scrollToTop();
});

//animation
new WOW().init();

// fixed menu
var header = $('.header .header-bottom');
var headerOffset = header.offset().top;
$(window).scroll(function () {
  if ($(window).scrollTop() > headerOffset) {
    header.addClass('fixed');
  } else {
    header.removeClass('fixed');
  }
});

// video hero
$(document).ready(function () {
  function updateVideoSource() {
    var video = $('#video-bg');
    var desktopSource = video.data('desktop');
    var mobileSource = video.data('mob');
    var screenWidth = $(window).width();
    var source = video.find('source');
    if (screenWidth > 576) {
      source.attr('src', desktopSource);
    } else {
      source.attr('src', mobileSource);
    }
    video[0].load();
  }
  updateVideoSource();
});
// $(window).resize(function() {
//     updateVideoSource();
// });

// faq

$(".faq-item-top").click(function () {
  var parent = $(this).parent();
  $(".faq-item").not(parent).removeClass("active").find(".faq-item-bottom").slideUp(300).removeClass('active');
  $(".faq-item-inner").removeClass("active").find(".faq-item-inner-bottom").slideUp(300).removeClass('active');
  parent.toggleClass("active");
  parent.find(".faq-item-bottom").slideToggle(300).toggleClass('active');
});
$(".faq-item-inner-top").click(function () {
  var parent = $(this).closest(".faq-item-inner");
  $(".faq-item-inner").not(parent).removeClass("active").find(".faq-item-inner-bottom").slideUp(300).removeClass('active');
  parent.toggleClass("active");
  parent.find(".faq-item-inner-bottom").slideToggle(300).toggleClass('active');
});

// show more button
// $(".show-more").click(function () {
//     let reviewText = $(this).parent().find("p");
//     let button = $(this);
//     let fullText = reviewText.attr('data-full-text');
//
//     if (reviewText.hasClass('show-less')) {
//         // Если текст уже полностью отображен, скрываем лишнее
//         reviewText.removeClass('show-less');
//         if (reviewText.parent().parent().hasClass('solves-item')) {
//             reviewText.html(reviewText.html().substring(0, 300) + '...');
//         }
//         else {
//             reviewText.html(reviewText.html().substring(0, 200) + '...');
//         }
//
//         button.html('Показать більше  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
//             '<path fill-rule="evenodd" clip-rule="evenodd" d="M11.7488 6.36426C12.0192 6.63462 12.0192 7.07297 11.7488 7.34333L6.85348 12.2387C6.58312 12.509 6.14477 12.509 5.87441 12.2387L0.979054 7.34333C0.708691 7.07297 0.708691 6.63462 0.979054 6.36426C1.24942 6.09389 1.68776 6.09389 1.95812 6.36426L5.67164 10.0778L5.67164 0.979368C5.67164 0.597016 5.98159 0.287059 6.36394 0.28706C6.7463 0.28706 7.05625 0.597017 7.05625 0.979368L7.05625 10.0778L10.7698 6.36426C11.0401 6.09389 11.4785 6.09389 11.7488 6.36426Z" fill="#7FB90D"/>\n' +
//             '</svg>\n');
//     } else {
//         // Полностью отображаем текст
//         reviewText.addClass('show-less');
//         reviewText.html(fullText);
//         button.html('Приховати <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
//             '<path fill-rule="evenodd" clip-rule="evenodd" d="M11.7488 6.36426C12.0192 6.09389 12.0192 5.65555 11.7488 5.38519L6.85348 0.489832C6.58312 0.219469 6.14477 0.219469 5.87441 0.489832L0.979054 5.38519C0.708691 5.65555 0.708691 6.09389 0.979054 6.36426C1.24942 6.63462 1.68776 6.63462 1.95812 6.36426L5.67164 2.65075V11.7491C5.67164 12.1315 5.98159 12.4415 6.36394 12.4415C6.7463 12.4415 7.05625 12.1315 7.05625 11.7491L7.05625 2.65075L10.7698 6.36426C11.0401 6.63462 11.4785 6.63462 11.7488 6.36426Z" fill="#7FB90D"/>\n' +
//             '</svg>\n');
//     }
// });

function truncateText(reviewText) {
  var fullText = reviewText.attr('data-full-text');
  // Обрезаем текст до 200 или 300 символов в зависимости от контекста
  if (reviewText.parent().parent().hasClass('solves-item') && $(window).width() < 576) {
    reviewText.html(fullText.substring(0, 280) + '...');
  } else if (reviewText.parent().hasClass('review-project-text')) {
    reviewText.html(fullText.substring(0, 630) + '...');
  } else if (!reviewText.parent().parent().hasClass('solves-item')) {
    reviewText.html(fullText.substring(0, 200) + '...');
  }
}

// Вызываем truncateText для каждого элемента .show-more
$(".show-more").each(function () {
  var reviewText = $(this).parent().find("p");
  var button = $(this);
  truncateText(reviewText);

  // Назначаем обработчик события для кнопки
  button.click(function () {
    if (reviewText.hasClass('show-less')) {
      // Если текст уже полностью отображен, скрываем лишнее
      reviewText.removeClass('show-less');
      truncateText(reviewText);
      button.html('Показати більше  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">\n' + '<path fill-rule="evenodd" clip-rule="evenodd" d="M11.7488 6.36426C12.0192 6.63462 12.0192 7.07297 11.7488 7.34333L6.85348 12.2387C6.58312 12.509 6.14477 12.509 5.87441 12.2387L0.979054 7.34333C0.708691 7.07297 0.708691 6.63462 0.979054 6.36426C1.24942 6.09389 1.68776 6.09389 1.95812 6.36426L5.67164 10.0778L5.67164 0.979368C5.67164 0.597016 5.98159 0.287059 6.36394 0.28706C6.7463 0.28706 7.05625 0.597017 7.05625 0.979368L7.05625 10.0778L10.7698 6.36426C11.0401 6.09389 11.4785 6.09389 11.7488 6.36426Z" fill="#7FB90D"/>\n' + '</svg>\n');
    } else {
      // Полностью отображаем текст
      reviewText.addClass('show-less');
      reviewText.html(reviewText.attr('data-full-text'));
      button.html('Приховати <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">\n' + '<path fill-rule="evenodd" clip-rule="evenodd" d="M11.7488 6.36426C12.0192 6.09389 12.0192 5.65555 11.7488 5.38519L6.85348 0.489832C6.58312 0.219469 6.14477 0.219469 5.87441 0.489832L0.979054 5.38519C0.708691 5.65555 0.708691 6.09389 0.979054 6.36426C1.24942 6.63462 1.68776 6.63462 1.95812 6.36426L5.67164 2.65075V11.7491C5.67164 12.1315 5.98159 12.4415 6.36394 12.4415C6.7463 12.4415 7.05625 12.1315 7.05625 11.7491L7.05625 2.65075L10.7698 6.36426C11.0401 6.63462 11.4785 6.63462 11.7488 6.36426Z" fill="#7FB90D"/>\n' + '</svg>\n');
    }
  });
});

//popup form
var isOpen = false;
function openPopup() {
  $("#consult-popup").show(200).addClass('active');
  isOpen = true;
}
$('.faq-item span.link').click(function () {
  $("#consult-popup").show(200).addClass('active');
  isOpen = true;
});
$('#consult-popup .close-popup').click(function () {
  $("#consult-popup").hide().removeClass('active');
  isOpen = false;
});
var isTouch = function isTouch() {
  return 'ontouchstart' in window || window.DocumentTouch && document instanceof window.DocumentTouch || navigator.maxTouchPoints > 0 || window.navigator.msMaxTouchPoints > 0;
};
$(document).mouseup(function (e) {
  var popup = $('.popup');
  if (!isTouch()) {
    if (isOpen && !popup.is(e.target) && popup.has(e.target).length === 0) {
      $("#consult-popup").hide().removeClass('active');
      isOpen = false;
    }
  }
});

//popup form

var isThanksPopupOpen = false;
var isSubscribePopupOpen = false;
document.addEventListener('wpcf7mailsent', function (event) {
  $('.popup-wrapper').hide().removeClass("active");
  isOpen = false;
  $('#thanks-popup').show(200).addClass('active');
  isThanksPopupOpen = true;
}, false);
document.addEventListener('wpcf7mailsent', function (event) {
  var formElement = event.target.closest('[subscribe-form]');
  if (formElement) {
    $('.popup-wrapper').hide().removeClass("active");
    isOpen = false;
    $('#subscribe-popup').show(200).addClass('active');
    isSubscribePopupOpen = true;
  } else {
    $('.popup-wrapper').hide().removeClass("active");
    isOpen = false;
    $('#thanks-popup').show(200).addClass('active');
    isThanksPopupOpen = true;
  }
}, false);
$('#thanks-popup .close-popup').click(function () {
  $("#thanks-popup").hide().removeClass('active');
  isThanksPopupOpen = false;
});
$('#subscribe-popup .close-popup').click(function () {
  $("#subscribe-popup").hide().removeClass('active');
  isSubscribePopupOpen = false;
});
$(document).mouseup(function (e) {
  var popup = $('.popup');
  if (isThanksPopupOpen && !popup.is(e.target) && popup.has(e.target).length === 0) {
    $("#thanks-popup").hide().removeClass('active');
    isThanksPopupOpen = false;
  }
});

// popup projects

var projectsOpen = false;
function openPopupProjects() {
  $("#projects-popup").show(200).addClass('active');
  projectsOpen = true;
}
$('#projects-popup .close-popup').click(function () {
  $("#projects-popup").hide().removeClass('active');
  projectsOpen = false;
});
$('#projects-popup .primary-btn').click(function () {
  $("#projects-popup").hide().removeClass('active');
  projectsOpen = false;
});
$(document).mouseup(function (e) {
  var popup = $('.popup');
  if (projectsOpen && !popup.is(e.target) && popup.has(e.target).length === 0) {
    $("#projects-popup").hide().removeClass('active');
    projectsOpen = false;
  }
});

// animation

// Функция для определения, виден ли элемент на экране
function isElementInViewport(el) {
  if (!el) return false;
  var rect = el.getBoundingClientRect();
  return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
}

// Функция для обработки скролла
function handleScroll(className) {
  var elements = document.querySelectorAll('.' + className);
  var lastElement = elements[elements.length - 1];
  if (isElementInViewport(elements[0]) && !elements[0].classList.contains('show')) {
    elements.forEach(function (item, index) {
      setTimeout(function () {
        item.classList.add('show');
      }, index * 200); // Задержка в миллисекундах, умноженная на индекс элемента
    });
  } else if (isElementInViewport(lastElement) && !lastElement.classList.contains('show')) {
    elements.forEach(function (item, index) {
      setTimeout(function () {
        item.classList.add('show');
      }, index * 200); // Задержка в миллисекундах, умноженная на индекс элемента
    });
  }
}

// Обработка события скролла для класса '.info-item'

if ($(window).width() >= 800) {
  window.addEventListener('scroll', function () {
    // handleScroll('hero-home .right .primary-btn');
    handleScroll('info-item');
    handleScroll('plan-item');
  });

  // Запуск проверки при загрузке страницы
  window.addEventListener('load', function () {
    // handleScroll('hero-home .right .primary-btn');
    handleScroll('info-item');
    handleScroll('plan-item');
  });
} else {
  $('.info-home .info-item').addClass('show');
  $('.plan-home .plan-item').addClass('show');
  // $('.hero-home .right .primary-btn').addClass('show')
}

// preloader
$(window).on('load', function () {
  var isFirstLoad = localStorage.getItem('isFirstLoad');
  preloaderSimple();
  if (!isFirstLoad) {
    simulateLoading();
    localStorage.setItem('isFirstLoad', 'true');
  }
  function preloaderSimple() {
    setTimeout(function () {
      $('.preloader-simple').addClass('active');
      heroAnimation();
    }, 200);
  }

  // Имитация загрузки с прогрессом
  function simulateLoading() {
    $('.preloader').show(0).addClass('active');
    var percentageElement = $('#percentage');
    var currentPercentage = 0;
    var updateInterval = 15; // Интервал в миллисекундах

    function updatePercentage() {
      currentPercentage++;
      percentageElement.text(currentPercentage + '%');
      if (currentPercentage < 100) {
        setTimeout(updatePercentage, updateInterval);
      } else {
        hideLoader(); // Скрываем прелоадер по завершении
      }
    }

    updatePercentage();
  }
  function scrollToAnchor(anchor) {
    var element = document.querySelector(anchor);
    if (element) {
      var rect = element.getBoundingClientRect();
      var offset = 50; // Дополнительный отступ
      var targetPosition = rect.top + window.pageYOffset - offset;
      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth'
      });
    }
  }
  function hideLoader() {
    $('.preloader').fadeOut(500, function () {
      $(this).hide(); // Скрываем прелоадер после анимации
      $(this).removeClass('active');
      heroAnimation(); // Вызов функции анимации героев
    });
  }

  function heroAnimation() {
    if ($(window).width() >= 800) {
      $(window).on('scroll', function () {
        handleScroll('.hero-home .right .primary-btn');
      });
      handleScroll('.hero-home .right .primary-btn');
    } else {
      $('.hero-home .right .primary-btn').addClass('show');
    }
    $('.hero-home .left h1, .hero-home .left p').addClass('slide-up');
  }
  function handleScroll(selector) {
    var element = $(selector);
    if (element.length) {
      var windowTop = $(window).scrollTop();
      var elementTop = element.offset().top;
      if (windowTop >= elementTop - window.innerHeight) {
        element.addClass('show');
      } else {
        element.removeClass('show');
      }
    }
  }
  var preloader = document.querySelector('.preloader');
  if (preloader) {
    preloader.addEventListener('transitionend', function () {
      var hash = window.location.hash;
      if (hash) {
        scrollToAnchor(hash);
      }
    });
  }
  $('a').on('click', function (event) {
    if ($(this).closest('.projects-pagination').length) {
      return;
    }
    var href = $(this).attr('href');
    var target = $(this).attr('target');
    if (href && !href.startsWith('#') && !href.startsWith('tel:') && !href.startsWith('mailto:') && target !== '_blank') {
      event.preventDefault();
      $('.preloader-simple .preloader-end').addClass('active');

      // Переход по ссылке после небольшой задержки
      setTimeout(function () {
        window.location.href = href; // Переход по ссылке
      }, 500); // Задержка в 500 мс (можно изменить по необходимости)
    }
  });
});

// telephone mask
function telephoneMask() {
  var telephoneInputs = document.querySelectorAll('input[type="tel"]');
  if (telephoneInputs.length !== 0) {
    telephoneInputs.forEach(function (telephoneInput) {
      window.intlTelInput(telephoneInput, {
        initialCountry: "auto",
        excludeCountries: ["by", "ru"],
        separateDialCode: true,
        geoIpLookup: function geoIpLookup(callback) {
          fetch("https://ipapi.co/json").then(function (res) {
            return res.json();
          }).then(function (data) {
            return callback(data.country_code);
          })["catch"](function () {
            return callback("us");
          });
        },
        utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js"
        //utilsScript: "https://new.pro-energy.com.ua/wp-content/themes/ProEnergy/assets/lib/tel-mask/js/utils.js",
      });
    });
  }
}

telephoneMask();
$(".drop-btn").click(function () {
  $(this).toggleClass('active');
  $(this).closest('.section-body').find('.drop-content').slideToggle(300);
});

// map contact page
$('.hero-contacts-right button').click(function () {
  $('.hero-contacts-right img').fadeToggle(300);
  $(this).toggleClass('active');
  var buttonText = $(this).find('span');
  if (buttonText.text() === "Знайти нас на мапі") {
    buttonText.text("Приховати мапу");
  } else {
    buttonText.text("Знайти нас на мапі");
  }
});

// tabs
$(".tabs-item-top").click(function () {
  // $(".tabs-item-top").not($(this)).removeClass('active');
  // $(".tabs-item-bottom").not($(this).siblings('.tabs-item-bottom')).slideUp().removeClass('active');

  $(this).toggleClass('active');
  $(this).siblings('.tabs-item-bottom').slideToggle().toggleClass('active');
  var $slider = $(this).siblings('.tabs-item-bottom').find('[class^="systematic-swiper"]');
  var $pagination = $slider.parent().find('.dots');
  $slider.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
    var i = (currentSlide ? currentSlide : 0) + 1;
    var totalSlides = slick.$slides.length > 9 ? slick.$slides.length : '0' + slick.$slides.length;
    var html = '<span class="current-slide">' + (i >= 10 ? i : '0' + i) + '</span>' + '<span class="total-slides">' + ' / ' + totalSlides + '</span>';
    $pagination.html(html);
  });

  // Инициализируем слайдер
  $slider.slick({
    infinite: true,
    slidesToShow: 1,
    dots: false,
    prevArrow: $slider.parent().find('.prev'),
    nextArrow: $slider.parent().find('.next')
  });
});
var pageAboutHandler = function pageAboutHandler() {
  valuesSlider();
  historySlider();
  logoSlider();
  teamCards();
  mapAnimation();
  expandCard();
  tabsHandler();
  documentsSlider();
  scrollSectionHandler();
};
var valuesSlider = function valuesSlider() {
  if (!window.matchMedia("(max-width: 576px)").matches) {
    return;
  }
  var slider = document.querySelector('[data-values-slider]');
  if (!slider) {
    return;
  }
  var options = {
    infinite: true,
    slidesToShow: 1,
    prevArrow: '[data-values-slider-nav] .values-prev',
    nextArrow: '[data-values-slider-nav] .values-next',
    dots: false,
    adaptiveHeight: false
  };
  var paginationInfoStatus = document.querySelector('[data-values-slider-nav] .values-dots');
  $(slider).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
    var i = (currentSlide ? currentSlide : 0) + 1;
    var totalSlides = slick.$slides.length > 9 ? slick.$slides.length : '0' + slick.$slides.length;
    var html = "<span class=\"current-slide\">".concat(i >= 10 ? i : '0' + i, "</span>\n                    <span class=\"total-slides\">").concat(' / ' + totalSlides);
    paginationInfoStatus.innerHTML = html;
  });
  $(slider).slick(options);
};
var historySlider = function historySlider() {
  var historySlider = document.querySelector('[data-history-slider]');
  var historyDescriptionSlider = document.querySelector('[data-history-description-slider]');
  if (!historySlider) {
    return;
  }
  var historySliderOptions = {
    slidesToShow: 6,
    slidesToScroll: 1,
    dots: false,
    prevArrow: '.history-prev',
    nextArrow: '.history-next',
    //centerMode: true,
    asNavFor: '[data-history-description-slider]',
    focusOnSelect: true,
    draggable: false,
    infinite: false,
    responsive: [{
      breakpoint: 1025,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        draggable: true
      }
    }, {
      breakpoint: 769,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        draggable: true,
        arrows: false,
        centerMode: false
      }
    }]
  };
  var historyDescriptionSliderOption = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    draggable: false,
    fade: true,
    infinite: false,
    asNavFor: '[data-history-slider]',
    prevArrow: '.history-description-arrows .history-prev',
    nextArrow: '.history-description-arrows .history-next',
    responsive: [{
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        draggable: true,
        dots: false,
        arrows: true,
        adaptiveHeight: true
      }
    }]
  };
  $(historySlider).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
    if (currentSlide === 0 || currentSlide === undefined) {
      $('.history-arrows .history-prev').hide();
    } else {
      $('.history-arrows .history-prev').show();
    }
    if (currentSlide === slick.$slides.length - 1) {
      $('.history-arrows .history-next').hide();
    } else {
      $('.history-arrows .history-next').show();
    }
  });
  var paginationInfoStatus = document.querySelector('[data-history-description-slider] ~ .fraction-nav .history-dots');
  $(historyDescriptionSlider).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
    var i = (currentSlide ? currentSlide : 0) + 1;
    var totalSlides = slick.$slides.length > 9 ? slick.$slides.length : '0' + slick.$slides.length;
    var html = "<span class=\"current-slide\">".concat(i >= 10 ? i : '0' + i, "</span>\n                    <span class=\"total-slides\">").concat(' / ' + totalSlides);
    paginationInfoStatus.innerHTML = html;
  });
  $(historySlider).slick(historySliderOptions);
  $(historyDescriptionSlider).slick(historyDescriptionSliderOption);
};
var logoSlider = function logoSlider() {
  var slider = document.querySelector('[data-logo-slider]');
  if (!slider) {
    return;
  }
  var options = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    prevArrow: '.logo-prev',
    nextArrow: '.logo-next',
    infinite: true
  };
  var paginationInfoStatus = document.querySelector('[data-logo-slider] ~ .fraction-nav .logo-dots');
  $(slider).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
    var i = (currentSlide ? currentSlide : 0) + 1;
    var totalSlides = slick.$slides.length > 9 ? slick.$slides.length : '0' + slick.$slides.length;
    var html = "<span class=\"current-slide\">".concat(i >= 10 ? i : '0' + i, "</span>\n                    <span class=\"total-slides\">").concat(' / ' + totalSlides);
    paginationInfoStatus.innerHTML = html;
  });
  $(slider).slick(options);
};
var scrollSectionHandler = function scrollSectionHandler() {
  // check if gsap loaded/installed
  if ((typeof gsap === "undefined" ? "undefined" : _typeof(gsap)) !== 'object') {
    return;
  }
  gsap.registerPlugin(ScrollTrigger);
  var partnershipScrollTarget = document.getElementById("partnership-scroll-target");
  var mm = gsap.matchMedia();
  mm.add("(min-width: 1025px)", function () {
    var tweenOne = gsap.to(partnershipScrollTarget, {
      y: 570 - partnershipScrollTarget.clientHeight - 32,
      scrollTrigger: {
        trigger: "#partnership-scroll-section",
        pin: "#partnership-scroll-section",
        scrub: true,
        start: "top top",
        end: "+=1700px",
        markers: false
      }
    });
  });
};
var teamCards = function teamCards() {
  if (window.matchMedia("(max-width: 840px)").matches) {
    return;
  }
  var cards = document.querySelectorAll('[data-team-card]');
  var cardList = document.querySelector('[data-team-list]');
  var movingSpace = 0;
  if (!cards.length || !cardList) {
    return;
  }
  if (window.matchMedia("(min-width: 1201px)").matches) {
    movingSpace = 250;
  } else if (window.matchMedia("(max-width: 1200px) and (min-width: 841px)").matches) {
    movingSpace = 200;
  }
  var showMoreBtns = cardList.querySelectorAll('[data-team-card-btn-dt]');
  if (!showMoreBtns.length) {
    return;
  }
  showMoreBtns.forEach(function (btn) {
    btn.addEventListener('click', function (event) {
      var currentBtn = event.target.closest('button');
      if (!currentBtn) {
        return;
      }
      var currentCard = currentBtn.closest('.team-card');
      if (!currentCard) {
        return;
      }
      var currentCardDetails = currentCard.querySelector('.team-card__details-wrapper');
      if (!currentCardDetails) {
        return;
      }
      var visibleCardDetails = cardList.querySelector('.team-card__details-wrapper.visible');
      if (visibleCardDetails && visibleCardDetails !== currentCardDetails) {
        visibleCardDetails.classList.remove('visible');
        cardList.style.transform = 'translateX(-50%)';
        var showText = visibleCardDetails.closest('.team-card').querySelector('[data-team-card-btn-dt] .btn-caption').getAttribute('data-show');
        visibleCardDetails.closest('.team-card').querySelector('[data-team-card-btn-dt] .btn-caption').innerText = showText;
      }
      if (!currentCardDetails.classList.contains('visible')) {
        currentCardDetails.classList.add('visible');
        if (currentCard.classList.contains('first')) {
          cardList.style.transform = "translateX(calc(-50% + ".concat(movingSpace, "px))");
        }
        if (currentCard.classList.contains('last')) {
          cardList.style.transform = "translateX(calc(-50% - ".concat(movingSpace, "px))");
        }
        var lessText = currentBtn.querySelector('.btn-caption').getAttribute('data-less');
        currentBtn.querySelector('.btn-caption').innerText = lessText;
      } else {
        currentCardDetails.classList.remove('visible');
        cardList.style.transform = 'translateX(-50%)';
        var _showText = currentBtn.querySelector('.btn-caption').getAttribute('data-show');
        currentBtn.querySelector('.btn-caption').innerText = _showText;
      }
    });
  });
};
var mapAnimation = function mapAnimation() {
  animateMarkers();
  animatePopups();
};
var animateMarkers = function animateMarkers() {
  var markerCircles = document.querySelectorAll('.map-marker .map-marker__circle');
  if (!markerCircles.length) {
    return;
  }
  markerCircles.forEach(function (circle, index) {
    circle.style.animationDelay = (index + 1) / 10 + 's';
  });
};
var animatePopups = function animatePopups() {
  var markers = document.querySelectorAll('.map-block .map-marker');
  if (!markers.length) {
    return;
  }
  markers.forEach(function (marker) {
    var markerId = marker.dataset.mapMarkerId;
    var markerCoords = {
      x: marker.getBBox().x,
      //getBBox because marker is a <g> tag inside SVG
      y: marker.getBBox().y
    };
    if (markerId) {
      var targetPopup = document.querySelector("[data-map-popup-id=\"".concat(markerId, "\"]"));
      if (targetPopup) {
        var targetPopupWidth = targetPopup.getBoundingClientRect().width;
        var targetPopupHeight = targetPopup.getBoundingClientRect().height;
        targetPopup.style.top = markerCoords.y - targetPopupHeight - 10 + 'px';
        targetPopup.style.left = markerCoords.x - targetPopupWidth / 2 + 10 + 'px';
      }
    }
    marker.addEventListener('mouseenter', function (event) {
      var markerId = event.target.dataset.mapMarkerId;
      if (markerId) {
        var _targetPopup = document.querySelector("[data-map-popup-id=\"".concat(markerId, "\"]"));
        if (_targetPopup) {
          _targetPopup.classList.add('visible');
        }
      }
    });
    marker.addEventListener('mouseleave', function (event) {
      var markerId = event.target.dataset.mapMarkerId;
      if (markerId) {
        var _targetPopup2 = document.querySelector("[data-map-popup-id=\"".concat(markerId, "\"]"));
        if (_targetPopup2) {
          _targetPopup2.classList.remove('visible');
        }
      }
    });
  });
};
var expandCard = function expandCard() {
  var expandBtns = document.querySelectorAll('[data-team-card-btn]');
  if (!expandBtns.length) {
    return;
  }
  expandBtns.forEach(function (btn) {
    btn.addEventListener('click', function (event) {
      var currentBtn = event.target.closest('.team-card__btn');
      var currentBtnCaption = currentBtn.querySelector('.btn-caption');
      if (!currentBtn) {
        return;
      }
      currentBtn.closest('[data-team-card]').querySelector('.team-card__details-wrapper').classList.toggle('expanded');
      currentBtn.classList.toggle('team-card-expanded');
      if (currentBtnCaption) {
        if (currentBtnCaption.innerText === 'Показати більше') {
          currentBtnCaption.innerText = 'Показати менше';
        } else {
          currentBtnCaption.innerText = 'Показати більше';
        }
      }
    });
  });
};
var tabsHandler = function tabsHandler() {
  var tabBtns = document.querySelectorAll('.tab-nav__btn');
  var tabs = document.querySelector('.tabs');
  if (!tabBtns.length || !tabs) {
    return;
  }
  tabBtns.forEach(function (tabBtn) {
    tabBtn.addEventListener('click', function (event) {
      toggleTabBtn(tabBtns, event.target);
      toggleTab(tabs, event.target.dataset.tabId);
    });
  });
  var toggleTab = function toggleTab(tabs, currentTabId) {
    tabs.querySelector('.active').classList.remove('active');
    tabs.querySelector("[data-tab-target=\"".concat(currentTabId, "\"]")).classList.add('active');
  };
  var toggleTabBtn = function toggleTabBtn(tabBtns, currentTabBtn) {
    tabBtns.forEach(function (tabBtn) {
      tabBtn.classList.remove('active');
    });
    currentTabBtn.classList.add('active');
  };
};
var documentsSlider = function documentsSlider() {
  var slider = document.querySelector('[data-documents-slider]');
  if (!slider) {
    return;
  }
  var options = {
    infinite: true,
    slidesToShow: 3,
    prevArrow: '.doc-prev',
    nextArrow: '.doc-next',
    dots: false,
    adaptiveHeight: false,
    responsive: [{
      breakpoint: 801,
      settings: {
        slidesToShow: 2
      }
    }, {
      breakpoint: 576,
      settings: {
        slidesToShow: 1
      }
    }]
  };
  $(slider).slick(options);
};
window.addEventListener('load', function () {
  pageAboutHandler();
});
var pageBiomassHandler = function pageBiomassHandler() {
  solutionsSlider();
  mobileTabs();
};
var mobileTabs = function mobileTabs() {
  var tabBtns = document.querySelectorAll('.tab-title');
  if (!tabBtns.length) {
    return;
  }
  tabBtns.forEach(function (btn) {
    btn.addEventListener('click', function (event) {
      event.target.closest('.tab-title').classList.toggle('active');
    });
  });
};
var solutionsSlider = function solutionsSlider() {
  var slider = document.querySelector('[data-solutions-slider]');
  if (!slider) {
    return;
  }
  var options = {
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: false,
    prevArrow: '.solutions-prev',
    nextArrow: '.solutions-next',
    infinite: true,
    responsive: [{
      breakpoint: 680,
      settings: {
        slidesToShow: 1
      }
    }]
  };
  var paginationInfoStatus = document.querySelector('[data-solutions-slider-nav] .solutions-dots');
  $(slider).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
    var i = (currentSlide ? currentSlide : 0) + 1;
    var totalSlides = slick.$slides.length > 9 ? slick.$slides.length : '0' + slick.$slides.length;
    var html = "<span class=\"current-slide\">".concat(i >= 10 ? i : '0' + i, "</span>\n                    <span class=\"total-slides\">").concat(' / ' + totalSlides);
    paginationInfoStatus.innerHTML = html;
  });
  if (window.matchMedia("(max-width: 1024px)").matches) {
    $(slider).slick(options);
  }
  window.addEventListener('resize', function () {
    if ($(window).width() > 1024) {
      if ($(slider).hasClass('slick-initialized')) {
        $(slider).slick('unslick');
      }
      return;
    }
    if (!$(slider).hasClass('slick-initialized')) {
      return $(slider).slick(options);
    }
  });
};
window.addEventListener('load', function () {
  pageBiomassHandler();
});
$(document).ready(function () {
  var paginationStatus = document.querySelector('.projects-dots');
  var paginationReviewsStatus = document.querySelector('.reviews-dots');
  var paginationGalleryStatus = document.querySelector('.gallery-wrapper .fraction-nav .dots');
  $(".projects-swiper").on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
    var i = (currentSlide ? currentSlide : 0) + 1;
    var totalSlides = slick.$slides.length > 9 ? slick.$slides.length : '0' + slick.$slides.length;
    var html = "<span class=\"current-slide\">".concat(i >= 10 ? i : '0' + i, "</span>\n\n                    <span class=\"total-slides\">").concat(' / ' + totalSlides);
    paginationStatus.innerHTML = html;
  });
  $(".reviews-swiper").on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
    var i = (currentSlide ? currentSlide : 0) + 1;
    var totalSlides = slick.$slides.length > 9 ? slick.$slides.length : '0' + slick.$slides.length;
    var html = "<span class=\"current-slide\">".concat(i >= 10 ? i : '0' + i, "</span>\n\n                    <span class=\"total-slides\">").concat(' / ' + totalSlides);
    paginationReviewsStatus.innerHTML = html;
  });
  $(".gallery-swiper").on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
    var i = (currentSlide ? currentSlide : 0) + 1;
    var totalSlides = slick.$slides.length > 9 ? slick.$slides.length : '0' + slick.$slides.length;
    var html = "<span class=\"current-slide\">".concat(i >= 10 ? i : '0' + i, "</span>\n\n                    <span class=\"total-slides\">").concat(' / ' + totalSlides);
    paginationGalleryStatus.innerHTML = html;
  });
  $('.projects-swiper').slick({
    infinite: true,
    prevArrow: '.projects-prev',
    nextArrow: '.projects-next',
    dots: false
  });
  $('.reviews-swiper').slick({
    infinite: true,
    slidesToShow: 2,
    prevArrow: '.reviews-prev',
    nextArrow: '.reviews-next',
    dots: false,
    adaptiveHeight: true,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        adaptiveHeight: true
      }
    }]
  });
  if ($(window).width() <= 576) {
    var paginationInfoStatus = document.querySelector('.info-nav-mobile .info-dots');
    $(".info-home-swiper").on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
      var i = (currentSlide ? currentSlide : 0) + 1;
      var totalSlides = slick.$slides.length > 9 ? slick.$slides.length : '0' + slick.$slides.length;
      var html = "<span class=\"current-slide\">".concat(i >= 10 ? i : '0' + i, "</span>\n\n                    <span class=\"total-slides\">").concat(' / ' + totalSlides);
      paginationInfoStatus.innerHTML = html;
    });
    $('.info-home-swiper').slick({
      infinite: true,
      slidesToShow: 1,
      prevArrow: '.info-nav-mobile .info-prev',
      nextArrow: '.info-nav-mobile .info-next',
      dots: false,
      adaptiveHeight: true
    });
    var paginationSolvesStatus = document.querySelector('.solves-nav-mobile .info-dots');
    $(".swiper-solves").on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
      var i = (currentSlide ? currentSlide : 0) + 1;
      var totalSlides = slick.$slides.length > 9 ? slick.$slides.length : '0' + slick.$slides.length;
      var html = "<span class=\"current-slide\">".concat(i >= 10 ? i : '0' + i, "</span>\n\n                    <span class=\"total-slides\">").concat(' / ' + totalSlides);
      paginationSolvesStatus.innerHTML = html;
    });
    $('.swiper-solves').slick({
      infinite: true,
      slidesToShow: 1,
      prevArrow: '.solves-nav-mobile .info-prev',
      nextArrow: '.solves-nav-mobile .info-next',
      dots: false,
      adaptiveHeight: true
    });
    $('[one-item-mob]').each(function () {
      var paginationMobStatus = $(this).parent().find('.dots');
      $(this).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
        var i = (currentSlide ? currentSlide : 0) + 1;
        var totalSlides = slick.$slides.length > 9 ? slick.$slides.length : '0' + slick.$slides.length;
        var html = "<span class=\"current-slide\">".concat(i >= 10 ? i : '0' + i, "</span>\n                  <span class=\"total-slides\">").concat(' / ' + totalSlides);
        paginationMobStatus.html(html);
      });
      $(this).slick({
        infinite: true,
        slidesToShow: 1,
        prevArrow: $(this).parent().find('.prev'),
        nextArrow: $(this).parent().find('.next'),
        dots: false,
        adaptiveHeight: true
      });
    });
  }
  $('.gallery-swiper').slick({
    centerMode: true,
    centerPadding: '60px',
    slidesToShow: 3,
    prevArrow: '.gallery-wrapper .prev',
    nextArrow: '.gallery-wrapper .next',
    dots: false,
    responsive: [{
      breakpoint: 768,
      settings: {
        centerPadding: '40px',
        slidesToShow: 1
      }
    }]
  });
  $('[one-item-desktop]').each(function () {
    var paginationDesktopStatus = $(this).parent().find('.dots');
    $(this).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
      var i = (currentSlide ? currentSlide : 0) + 1;
      var totalSlides = slick.$slides.length > 9 ? slick.$slides.length : '0' + slick.$slides.length;
      var html = "<span class=\"current-slide\">".concat(i >= 10 ? i : '0' + i, "</span>\n                  <span class=\"total-slides\">").concat(' / ' + totalSlides);
      paginationDesktopStatus.html(html);
    });
    $(this).slick({
      infinite: true,
      slidesToShow: 1,
      prevArrow: $(this).parent().find('.prev'),
      nextArrow: $(this).parent().find('.next'),
      dots: false,
      adaptiveHeight: true
    });
  });

  // $('[class^="systematic-swiper"]').each(function(index) {
  //     let $slider = $(this);
  //     let $pagination = $slider.parent().find('.dots');
  //
  //     $slider.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide) {
  //         let i = (currentSlide ? currentSlide : 0) + 1;
  //         let totalSlides = slick.$slides.length > 9 ? slick.$slides.length : '0' + slick.$slides.length;
  //         let html = '<span class="current-slide">' + (i >= 10 ? i : '0' + i) + '</span>' +
  //             '<span class="total-slides">' + ' / ' + totalSlides + '</span>';
  //         $pagination.html(html);
  //     });
  //
  //     // Инициализируем слайдер
  //     $slider.slick({
  //         infinite: true,
  //         slidesToShow: 1,
  //         dots: false,
  //         prevArrow:  $slider.parent().find('.prev'),
  //         nextArrow:  $slider.parent().find('.next'),
  //     });
  // });
});

// function marquee() {
//     const marqueeElementsDt = document.querySelectorAll("[data-marquee]");
//
//     if (marqueeElementsDt.length !== 0) {
//
//         marqueeElementsDt.forEach((el) => animateMarquee(el, 20000));
//     }
// }
//
//
// function animateMarquee(el, duration) {
//
//     const innerEl = el.querySelector(".marquee-inner");
//
//     const innerWidth = innerEl.offsetWidth;
//
//     const cloneEl = innerEl.cloneNode(true);
//
//     el.appendChild(cloneEl);
//
//
//     let start = performance.now();
//
//     let progress;
//
//     let translateX;
//
//
//
//     requestAnimationFrame(function step(now) {
//
//         progress = (now - start) / duration;
//
//
//
//         if (progress > 1) {
//
//             progress %= 1;
//
//             start = now;
//
//         }
//
//
//
//         translateX = innerWidth * progress;
//
//
//
//         innerEl.style.transform = `translate3d(-${translateX}px, 0 , 0)`;
//
//         cloneEl.style.transform = `translate3d(-${translateX}px, 0 , 0)`;
//
//
//
//         requestAnimationFrame(step);
//
//     });
//
// }
//
// marquee();

var animationPaused = false;
var animationProgress = 0;
var animationStartTime = null;
function marquee() {
  var marqueeElementsDt = document.querySelectorAll("[data-marquee]");
  if (marqueeElementsDt.length !== 0) {
    if (window.innerWidth > 768) {
      marqueeElementsDt.forEach(function (el) {
        return animateMarquee(el, 20000);
      });
    } else {
      marqueeElementsDt.forEach(function (el) {
        return animateMarquee(el, 35000);
      });
    }
  }
}
function animateMarquee(el, duration) {
  var innerEl = el.querySelector(".marquee-inner");
  var innerWidth = innerEl.offsetWidth;
  var cloneEl = innerEl.cloneNode(true);
  el.appendChild(cloneEl);
  function step(now) {
    if (!animationStartTime) {
      animationStartTime = now;
    }
    if (!animationPaused) {
      animationProgress = (now - animationStartTime) / duration;
      if (animationProgress > 1) {
        animationProgress %= 1;
        animationStartTime = now;
      }
      var translateX = innerWidth * animationProgress;
      innerEl.style.transform = "translate3d(-".concat(translateX, "px, 0 , 0)");
      cloneEl.style.transform = "translate3d(-".concat(translateX, "px, 0 , 0)");
    }
    requestAnimationFrame(step);
  }
  requestAnimationFrame(step);
  el.addEventListener('mouseenter', function () {
    animationPaused = true;
  });
  el.addEventListener('mouseleave', function () {
    animationPaused = false;
    animationStartTime = performance.now() - animationProgress * duration;
  });
  if (window.innerWidth < 768) {
    $('.marquee-inner a').on('click', function () {
      animationPaused = true;
      setTimeout(function () {
        animationPaused = false;
        animationStartTime = performance.now() - animationProgress * duration;
      }, 4000);
    });
  }
}
marquee();
$(".totop").click(function () {
  $("html, body").animate({
    scrollTop: 0
  }, "slow");
  return false;
});